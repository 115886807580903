import React, { Component } from "react";
import update from "immutability-helper";
import * as d3 from "d3";
import DeckGL, { ArcLayer, LinearInterpolator } from "deck.gl";
import { StaticMap } from "react-map-gl";

// API key
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoibm9lbHRvY2siLCJhIjoiY2pwcGhidTJuMDI2eDN3b2EweHR1dWZzaCJ9.12aAGzu2My2Ymp7WBjbSKg";

// Initial Viewport
const transitionInterpolator = new LinearInterpolator(["bearing"]);
const DECK_VIEW_STATE = {
  longitude: 0,
  latitude: 50,
  zoom: 3,
  minZoom: 2,
  maxZoom: 3,
  pitch: 55,
  bearing: 0,
  continuousWorld: false,
  noWrap: true
};

// Viewport Controls
const DECK_CONTROLS = {
  scrollZoom: false,
  dragPan: false,
  doubleClickZoom: false,
  touchZoom: false,
  touchRotate: false,
  keyboard: false
};

// Helper: Check if flight segment has both data points necessary
let checkStartEnd = segment => {
  if (
    segment.hasOwnProperty("start_airport_latitude") &&
    segment.hasOwnProperty("end_airport_latitude")
  ) {
    return true;
  } else {
    return false;
  }
};

// Helper: Check if home airport
let checkHome = code => {
  if (code === "ZRH") {
    return [254, 127, 102, 120];
  } else {
    return [242, 111, 84, 120];
  }
};

// Helper: Get travel distance in km
let getDistance = (lat1, lon1, lat2, lon2) => {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

class FlightsMap extends Component {
  // Set defaults
  constructor() {
    super(); // Runs component before extending
    this.rotationStep = 0;
    this.state = {
      flights: [],
      viewState: DECK_VIEW_STATE
    };

    this._onLoad = this._onLoad.bind(this);
    this._onViewStateChange = this._onViewStateChange.bind(this);
    this._rotateCamera = this._rotateCamera.bind(this);
  }

  _onLoad() {
    this._rotateCamera();
  }

  _onViewStateChange({ viewState }) {
    this.setState({ viewState });
  }

  _rotateCamera() {
    // change bearing by 120 degrees.
    const bearing = this.state.viewState.bearing - 120;
    this.setState({
      viewState: {
        ...this.state.viewState,
        bearing,
        transitionDuration: 40000,
        transitionInterpolator,
        onTransitionEnd: this._rotateCamera
      }
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  // Put together the flight objects from the raw Tripit data
  createFlight = s => {
    // Construct Flight Array
    let singleFlight = [];
    singleFlight.from = [];
    singleFlight.from.name = s.from.name;
    singleFlight.from.coordinates = [
      Number(s.from.coordinates.lon),
      Number(s.from.coordinates.lat)
    ];
    singleFlight.from.color = checkHome(s.from.name);
    singleFlight.to = [];
    singleFlight.to.name = s.to.name;
    singleFlight.to.coordinates = [
      Number(s.to.coordinates.lon),
      Number(s.to.coordinates.lat)
    ];
    singleFlight.to.color = checkHome(s.to.name);
    singleFlight.distance = getDistance(
      s.from.coordinates.lon,
      s.from.coordinates.lat,
      s.to.coordinates.lon,
      s.to.coordinates.lat
    );

    // Add Array to State
    const pastFlights = this.state.flights;
    let newFlights = update(pastFlights, { $push: [singleFlight] });
    this.setState({ flights: newFlights });
  };

  // Grab data
  fetchData = () => {
    d3.json("/static/flights.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(data => {
      let counter = 0;
      data.map(flight => this.createFlight(flight));
    });
  };

  render() {
    const layers = [
      new ArcLayer({
        id: "flight-map",
        data: this.state.flights,
        pickable: true,
        getWidth: 2.5,
        getSourcePosition: d => d["from"]["coordinates"],
        getTargetPosition: d => d["to"]["coordinates"],
        getTargetColor: d => d["to"]["color"], //
        getSourceColor: d => d["from"]["color"]
      })
    ];

    return (
      <div className="flight-map-wrap">
        <DeckGL
          layers={layers}
          viewState={this.state.viewState}
          onLoad={this._onLoad}
          onViewStateChange={this._onViewStateChange}
          controller={DECK_CONTROLS}
        >
          <StaticMap
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            mapStyle="mapbox://styles/noeltock/cjqdnezwg010d2so0nrdgaj48"
          />
        </DeckGL>
      </div>
    );
  }
}

export default FlightsMap;
