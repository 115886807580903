import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer dark-bg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="white" points="0,100 100,0, 0,0" />
        </svg>
        <div className="footer-content">dadasd</div>
      </div>
    );
  }
}

export default Footer;
