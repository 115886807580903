import Link from "next/link";
import LayoutHome from "../components/LayoutHome";
import Footer from "../components/Footer";
import FlightsMap from "../components/FlightMap";

const Home = () => (
  <LayoutHome>
    <div className="home-stack">
      <div className="top">
        <nav>
          <Link href="/about/">
            <a>About</a>
          </Link>
          <Link href="/blog/">
            <a>Blog</a>
          </Link>
        </nav>
      </div>
      <div className="portrait-wrap">
        <img src="/static/nt-duotone.jpg" />
      </div>
      <div className="middle">
        <h1>Noel Tock</h1>
        <h3>Maker, nomad, crafting digital experiences for enterprise.</h3>
      </div>
      <FlightsMap />
      <div className="bottom">
        <span>Last 300 flights via D3/Mapbox</span>
      </div>
    </div>
  </LayoutHome>
);

export default Home;
